*, *:before, *:after
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-family: 'Proxima Nova'
  text-decoration: none
  font-weight: 400
  color: #fff
  outline: none
  padding: 0
  margin: 0
  box-sizing: border-box
  -webkit-box-sizing: border-box

body
  width: 100%
  height: 100%
  overflow: hidden

.widget-section
  width: 100%
  height: 100%
  .widget
    width: 100%
    height: 100%

.widget-logo img
  position: relative !important
  top: -40px !important
  width: 160px !important
  height: 160px !important
  @media screen and (max-width: 670px)
    width: 120px !important
    height: 120px !important

.widget-tab-button
  background-color: #FFFDED !important
  &--active
    background-color: #005123 !important

.widget-logo img
    width: 250px !important
