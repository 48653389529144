*, :before, :after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  padding: 0;
  font-family: Proxima Nova;
  font-weight: 400;
  text-decoration: none;
}

body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.widget-section, .widget-section .widget {
  width: 100%;
  height: 100%;
}

.widget-logo img {
  width: 160px !important;
  height: 160px !important;
  position: relative !important;
  top: -40px !important;
}

@media screen and (width <= 670px) {
  .widget-logo img {
    width: 120px !important;
    height: 120px !important;
  }
}

.widget-tab-button {
  background-color: #fffded !important;
}

.widget-tab-button--active {
  background-color: #005123 !important;
}

.widget-logo img {
  width: 250px !important;
}
/*# sourceMappingURL=index.4acddc59.css.map */
